import React from 'react'
import {Link} from 'gatsby'
import styled from 'styled-components'
import Heading from './heading'
import SummerWinterSchoolsArchivePreview from './summer-winter-schools-archive-preview'

import Pagination from './pagination'

import {colors, font, fontfamily} from '../styles/variables'

const Wrapper = styled.section`
  display: block;
  padding: 4rem 0;
  @media (min-width: 1024px) {
    max-width: 680px;
    margin: 0 auto;
  }
`

const List = styled.ul`
  display: block;
  margin: 0 2.8rem;
`

function SummerWinterSchoolsArchiveList (props) {
  const {currentPage, numPages} = props

  return (
    <Wrapper>
      <Heading title='サマー・ウィンタースクール' titleEn='Summer Winter Schools' />
      <List>
        {props.nodes &&
          props.nodes.map(node => (
            <li key={node._id} >
              <SummerWinterSchoolsArchivePreview {...node} isInList />
            </li>
          ))}
      </List>
      <Pagination currentPage={currentPage} numPages={numPages} slug='summer-winter-schools' />
    </Wrapper>
  )
}

SummerWinterSchoolsArchiveList.defaultProps = {
  nodes: []
}

export default SummerWinterSchoolsArchiveList
